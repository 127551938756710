import { useSetRecoilState } from 'recoil';
import { styled } from "styled-components";
import { messageModalInfo } from '../atoms/atoms';
import Avatar from "./Avatar";

const ActiveUser = ({ name, sid }) => {
  const setMessageModal = useSetRecoilState(messageModalInfo);

  const onClick = () => {
    setMessageModal({
      show: true,
      pid: sid,
      name: name
    });
  }

  return (
    <UserWrapper >
      <div onClick={()=>onClick()} >
        <Avatar name={name} />
      </div>
      <Name>{name}</Name>
    </UserWrapper>
  );
};

const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0px solid #000;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};
`;

const Name = styled.div`
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};
  text-align: center;
`;

export default ActiveUser;
