import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { joinInfo } from '../atoms/atoms';
import styled from 'styled-components';
// import socket from '../utils/socket';
import { db, resetDatabase } from '../utils/db';
import ActiveUsers from '../components/ActiveUsers';
import NavigationBar from '../components/NavigationBar';
// import ConnectionState from '../components/ConnectionState';
import MessageModal from '../components/MessageModal';
// import WorkerComponent from '../components/WorkerComponent';

export const MainRoom = () => {
  // BroadcastChannel 생성
  const channel = new BroadcastChannel('service-worker-channel');

  const navigate = useNavigate();
  const location = useLocation();
  const history = createBrowserHistory();
  const joinInfoData = useRecoilValue(joinInfo);

  const { register, handleSubmit, setValue } = useForm();
  // const [isConnected, setIsConnected] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [worker, setWorker] = useState(null);

  const isPersonalRoom = (path) => {
    const { pathname } = location;
    return pathname.endsWith('personal');
  }

  function enterChatRoom(myWorker, ipAddress, nickName) {
    myWorker.postMessage({action: 'enterChatRoom', jData: { ipAddress: ipAddress, nickName: nickName}});
  }

  const addChatMessage = (data) => {
    setChatList((list) => list.concat(data));
  }

  const onValid = (data) => {
    worker.postMessage({action: 'sendMessage', jData: { ipAddress: joinInfoData.ipAddress, message: data.sendMessage }});
  }

  const onActiveUserClick = (myWorker=null) => {
    if(!myWorker) {
      worker.postMessage({ action: 'activeUsers', jData: { ipAddress: joinInfoData.ipAddress }});
    } else {
      myWorker.postMessage({ action: 'activeUsers', jData: { ipAddress: joinInfoData.ipAddress }});
    }
  }

  const savePersonalMessageByOn = async ({type, fromName, fromSid, toName, toSid, message, date}) => {
    try {
      const id = await db.messages.add({ type, pid: fromSid, fromSid, fromName, toSid, toName, message, date });
      console.log(`${message} successfully added. Got id ${id}`);
    } catch (error) {
      console.log(`Failed to add ${message}: ${error}`);
    }

    try {
      console.log(`${fromSid} ${toName} successfully added.`);
      db.personalRoom.update(fromSid, { sid: fromSid, name: fromName, message, tag: 'new', date })
        .then(async (updated) => {
          if (updated)
            console.log ("Friend number 2 was renamed to Number 2");
          else {
            console.log ("Nothing was updated - there were no friend with primary key: 2");
            const id = await db.personalRoom.add({
              pid: fromSid,
              sid: fromSid,
              name: fromName,
              message,
              tag: 'new',
              date
            });
            console.log(`${message} successfully added. Got pid ${id}`);
          }
        }
      );
    } catch (error) {
      // console.log(`Failed to add ${message}: ${error}`);
    }
  }

  const savePersonalMessageByEmit = async ({ type, fromName, fromSid, toName, toSid, message, date }) => {

    try {
      const id = await db.messages.add({ type, pid: toSid, fromSid, fromName, toSid, toName, message, date });
      console.log(`${message} successfully added. Got id ${id}`);
    } catch (error) {
      console.log(`Failed to add ${message}: ${error}`);
    }

    try {
      console.log(`${toSid} ${toName} successfully added.`);
      db.personalRoom.update(toSid, { sid: toSid, name: toName, message, date })
        .then(async (updated) => {
          if (updated)
            console.log ("Friend number 2 was renamed to Number 2");
          else {
            console.log ("Nothing was updated - there were no friend with primary key: 2");
            const id = await db.personalRoom.add({
              pid: toSid,
              sid: toSid,
              name: toName,
              message,
              date
            });
            console.log(`${message} successfully added. Got pid ${id}`);
          }
        }
      );
    } catch (error) {
      // console.log(`Failed to add ${message}: ${error}`);
    }
  }

  const goJoinPage = () => {
    resetDatabase();
    navigate('/');
  }

  const preventGoBack = () => {
    history.push(null, '', history.location.href);
    alert("뒤로가기 없음!!!");
  }

  const preventClose = (e) => {
    e.preventDefault();
    e.returnValue = ""; // chrome에서는 설정이 필요해서 넣은 코드
  }

  useEffect(() => {

    const { ipAddress, nickName } = joinInfoData;
    console.log(ipAddress +", "+nickName);
    if( !ipAddress || !nickName ) {
      navigate('/');
      return false;
    }

    const myWorker = new Worker(new URL('../workers/worker.js', import.meta.url));
    setWorker(myWorker);

    myWorker.onmessage = async function (event) {
      // console.log('Received result from worker:', event.data);
      const { action, jData } = event.data;
      switch (action) {
        case 'connect':
          // setIsConnected(true);
          break;
        case 'connect_error':
          console.log("connect_error!!!");
          console.log(jData.error);
          alert("잠시 서버와 연결이 종료되었습니다.");
          goJoinPage();
          break;
        case 'disconnect':
          // setIsConnected(false);
          alert("서버와 연결이 종료되었습니다.");
          goJoinPage();
          break;
        case 'enterChatRoom':
          onActiveUserClick(myWorker);
          break;
        case 'welcome':
          addChatMessage(jData);
          onActiveUserClick(myWorker);
          break;
        case 'sendMessage':
          setValue("sendMessage", '');
          addChatMessage({type: 'you', name: 'You', message: jData.message});
          break;
        case 'getMessage':
          addChatMessage(jData);
          channel.postMessage({name: jData.name, message: jData.message});
          break;
        case 'sendPersonalMessage':
          savePersonalMessageByEmit(jData);
          break;
        case 'getPersonalMessage':
          savePersonalMessageByOn(jData);
          channel.postMessage({name: jData.name, message: jData.message});
          break;
        case 'activeUsers':
          try {
            db.activeUsers.clear();
            await db.activeUsers.bulkAdd(jData.users);
          } catch (error) {
            console.log(`Failed to bulkAdd: ${error}`);
          }
          break;
        default:
          break;
      }
    };

    enterChatRoom(myWorker, ipAddress, nickName);

    (() => {
      history.push(null, '', history.location.href);
      window.addEventListener('popstate', preventGoBack);
      window.addEventListener("beforeunload", preventClose);
    })();

    return () => {
      myWorker.terminate();
      window.removeEventListener("popstate", preventGoBack);
      window.removeEventListener("beforeunload", preventClose);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainRoomWrapper>
      <NavigationBar />
      <MainRoomComponent>
        <h1>
          Cheer Talk
        </h1>
        <h2 onClick={()=>onActiveUserClick()}>Activities</h2>
        <ActiveUsers />
        {isPersonalRoom() ? (
          <h2>Personal Messages</h2>
        ) : (
          <h2>Main room</h2>
        )}
        <Outlet context={{chatList}}/>
        {isPersonalRoom() || (
          <SendMessageWrapper onSubmit={handleSubmit(onValid)}>
            <input
              {...register("sendMessage", { required: true})}
              placeholder="Your message"
            />
            <button type='submit'>Send</button>
          </SendMessageWrapper>
        )}
      </MainRoomComponent>
      <MessageModal worker={worker} savePersonalMessageByOn={savePersonalMessageByOn}/>
    </MainRoomWrapper>
  );
};

const MainRoomWrapper = styled.section`
  margin: 0;
  padding: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  & h1 {
    padding: 2.5rem 0 0 1.5rem;
    height: 9rem;
    font-size: 3.4rem;
    font-weight: 600;
  }
  & h2 {
    padding-left: 2.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    height: 2.4rem;
  }
`;

const MainRoomComponent = styled.div`
  position: relative;
  padding-left: 6.5rem;
  width: 100vw;
  height: 100vh;
`;

const SendMessageWrapper = styled.form`
  padding: 0 2rem 0 8rem;
  width: 100vw;
  height: 7rem;

  position: fixed;
  left: 0rem;
  bottom: 0rem;

  display: flex;
  align-items: center;
  gap: 2rem;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};
  & input {
    padding-left: 2.4rem;
    width: 100vw;
    height: 4.5rem;
    color: #999;
    border: 0.1rem solid #e7e6ea;
    border-radius: 1.2rem;
    outline: none;
  }
  & button {
    width: 9rem;
    height: 4.8rem;
    color: #fff;
    background-color: #e94057;
    border: 0.1rem solid #e94057;
    border-radius: 1.2rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
`;