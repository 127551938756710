import React from 'react';
import styled from 'styled-components';

const NewIcon = ({size=3}) => {
  return (
    <NewIconWrapper src='/img/new-icon.png' alt='new tag' size={size}/>
  );
};

const NewIconWrapper = styled.img`
  width: ${(props) => `${props.size}rem`};
  height: ${(props) => `${props.size}rem`};
`;

export default NewIcon;