import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faComments, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { UrlPaths } from '../atoms/atoms';
import { useLiveQuery } from 'dexie-react-hooks';
import { db, resetDatabase } from '../utils/db';
import NewIcon from './NewIcon';

// const NavigationBar = ({worker}) => {
const NavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const numberNewTags = useLiveQuery(
    async () => {
      //
      // Query Dexie's API
      //
      const newTagCount = await db.personalRoom.where({tag: 'new'}).count();

      // Return result
      return newTagCount;
    },
    // specify vars that affect query:
  );

  const isActive = (path) => {
    const { pathname } = location;
    return pathname.endsWith(path);
  }

  const onClick = () => {
    // socket.disconnect();
    resetDatabase();
    navigate('/');
  }

  return (
    <NavWrapper>
      <ul>
        <NavButton $actives={isActive(UrlPaths.main)}>
          <Link to={"/main"}>
          {isActive(UrlPaths.main) ? (
              <FontAwesomeIcon icon={faHouseUser} size="2xl" style={{color: "#e94057",}} />
            ) : (
              <FontAwesomeIcon icon={faHouseUser} size="2xl" style={{color: "#adafbb",}} />
            )
          }
          </Link>
        </NavButton>
        <NavButton $actives={isActive(UrlPaths.personal)}>
          <Link to={"/main/personal"}>
            {isActive(UrlPaths.personal) ? (
                <FontAwesomeIcon icon={faComments} size="2xl" style={{color: "#e94057",}} />
              ) : (
                <FontAwesomeIcon icon={faComments} size="2xl" style={{color: "#adafbb",}} />
              )
            }
          </Link>
          {numberNewTags > 0 && (
            <NewIconWrapper>
              <NewIcon size={1.3}/>
            </NewIconWrapper>
          )}
        </NavButton>
        <NavButton $actives={false}>
          <div onClick={onClick}>
            <FontAwesomeIcon icon={faArrowRightFromBracket} size="2xl" style={{color: "#adafbb",}} />
          </div>
        </NavButton>
      </ul>
    </NavWrapper>
  );
}

const NavWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 2.5rem;
  width: 6.5rem;
  height: 100vh;
  background-color: ${(props) => props.theme.navBgColor};
  z-index: 100;
  & ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

const NavButton = styled.li`
  position: relative;
  width: 6rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    cursor: pointer;
  }
`;

const NewIconWrapper = styled.span`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export default NavigationBar;