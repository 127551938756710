
export const lightTheme = {
  bgColor: '#fff',
  textColor: '#000',
  navBgColor: '#f3f3f3',
};

export const darkTheme = {
  bgColor: '#282828',
  textColor: '#e3e3e3',
  navBgColor: '#2c2c2c',
};