import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';

const Avatar = ({ name }) => {
  return (
    <div>
      <FontAwesomeIcon icon={faCircleUser} style={{color: "#dddddd",height: "5.3rem"}} />
    </div>
  );
};

export default Avatar;
