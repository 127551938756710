import { createBrowserRouter } from 'react-router-dom';
import Root from '../Root';
import { Join } from '../screens/Join';
import { MainRoom } from '../screens/MainRoom';
import { PersonalRoom } from '../screens/PersonalRoom';
import { NotFound } from '../screens/NotFound';
import MainRoomMessages from '../components/MainRoomMessages';
import ErrorComponent from '../components/ErrorComponent';


const router = createBrowserRouter([
  {
    path:"/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Join />,
        errorElement: <ErrorComponent />
      },
      {
        path: "main",
        element: <MainRoom />,
        children: [
          {
            path: "",
            element: <MainRoomMessages />,
            errorElement: <ErrorComponent />
          },
          {
            path: "personal",
            element: <PersonalRoom />,
            errorElement: <ErrorComponent />
          },
        ],
      },
    ],
    errorElement: <NotFound />
  }
]);

export default router;