import React from 'react';
import { styled } from 'styled-components';
import { useSetRecoilState } from 'recoil';
import { messageModalInfo } from '../atoms/atoms';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../utils/db';
import Avatar from '../components/Avatar';
import NewIcon from '../components/NewIcon';
import { timeForToday } from '../utils/timeUtils'


export const PersonalRoom = () => {
  const setMessageModal = useSetRecoilState(messageModalInfo);

  const personalRooms = useLiveQuery(
    async () => {
      //
      // Query Dexie's API
      //
      const rooms = await db.personalRoom.toArray();

      // Return result
      return rooms;
    },
    // specify vars that affect query:
  );

  const onRowClick = (pid, name) => {
    setMessageModal({
      show: true,
      pid: pid,
      name: name
    });
  }
  return (
    <PersonalRoomMessagesWrapper>
      <ul>
        { personalRooms ? (
            personalRooms.map((row, i) => (
              <li key={i} onClick={()=> onRowClick(row.pid, row.name)}>
                <Avatar name={row.name}/>
                <PersonalRoomInfo>
                  <PersonalRoomName>
                    <div>{row.name}</div>
                    <span>{`${timeForToday(row.date)}`}</span>
                  </PersonalRoomName>
                  <Message>
                    {row.message}
                  </Message>
                  { row.tag === 'new' && (
                    <NewIconWrapper>
                      <NewIcon size={2.2} />
                    </NewIconWrapper>
                  )}
                </PersonalRoomInfo>
              </li>
            ))
          ) : (
            <></>
          )
        }
      </ul>
    </PersonalRoomMessagesWrapper>
  );
};

const PersonalRoomMessagesWrapper = styled.section`
  position: absolute;
  inset: 23.4rem 0 0 6.5rem;

  margin: 0;
  padding: 0 2.2rem;
  overflow: auto;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};

  & ul {
    position: relative;
    padding: 0rem 0rem;
    height: inherit;
    overflow: auto;
    & li {
      margin: 1rem 0;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
      cursor: pointer;
    }
  }
`;

const NewIconWrapper = styled.span`
  position: absolute;
  top: 2.3rem;
  right: 1.9rem;
`;

const PersonalRoomInfo = styled.div`
  position: relative;
  padding: 0 2rem 0 0;
  width: 70%;
  border-bottom: 0.1rem solid #edecef;
`;

const PersonalRoomName = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  font-size: 1.3rem;
  font-weight: 600;
  & span {
    font-size: 1rem;
    color: ${(props) => props.theme.textColor};
    opacity: 0.4;
  }
`;

const Message = styled.div`
  position:relative;
  padding: 1.4rem 0rem 1.8rem;
  width: 95%;

  color: #000;
  background-color: '#fff';

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;