import { useState, useRef, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { styled } from 'styled-components';
import ActiveUser from './ActiveUser';

const MainRoomMessages = () => {
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef(null);

  const { chatList } = useOutletContext();



  useEffect(() => {
    if (listRef.current) {
      const height = listRef.current.scrollHeight;

      if (listHeight < height) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
      return setListHeight(height);
    }
  }, [chatList.length, listHeight]);


  return (
    <MainRoomMessagesWrapper ref={listRef}>
      <div>
        <ul>
          {chatList && (
            chatList.map((row, i) => {
              switch(row.type) {
                case 'you':
                  return (
                    <MessageRow key={i} $isRight={true}>
                      <Message $isRight={true} $isEven={i%2}>
                        {row.message}
                      </Message>
                      <ActiveUser name={row.name}/>
                    </MessageRow>
                  );
                case 'welcome':
                  return (
                    <WelcomeMessageRow key={i}>
                      <div>
                        {`${row.name} has just entered the chat`}
                      </div>
                    </WelcomeMessageRow>
                  );
                default:
                  return (
                    <MessageRow key={i} $isRight={false}>
                      <ActiveUser name={row.name}/>
                      <Message $isRight={false} $isEven={i%2}>
                        {row.message}
                      </Message>
                    </MessageRow>
                  );
              }
            }
          ))}
        </ul>
      </div>
    </MainRoomMessagesWrapper>
  );
};

const MainRoomMessagesWrapper = styled.section`
  position: absolute;
  inset: 23.4rem 0 6.5rem 6.5rem;

  margin: 0;
  padding: 0 2.2rem;
  overflow: auto;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};

  &>div {
    padding-top: 2.4rem;
    position: relative;
    overflow: auto;
    & ul {
      padding: 0rem 0rem 1rem;
    }
  }
`;

const MessageRow = styled.li`
  margin: 0;
  display: flex;
  justify-content: ${props => props.$isRight? `flex-end;`:`flex-start;` };
  align-items: center;
  gap: 2rem;
`;

const Message = styled.div`
  position:relative;
  padding: 1.8rem 3rem;
  font-size: 1.2rem;
  color: #000;
  border-radius: 1rem;
  background-color: ${props => props.$isEven? `#f3f3f3`: `#fdf2f3` };
  &:after {
    content:"";
    position: absolute;
    bottom: 1.1rem;
    ${props => props.$isRight? `right: -1rem;`: `left: -1rem;` };
    ${props => props.$isRight? (
      `border-left: 2rem solid ${props.$isEven? `#f3f3f3`: `#fdf2f3`};`
    ) : (
      `border-right: 2rem solid ${props.$isEven? `#f3f3f3`: `#fdf2f3`};`
    )};
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
  }
`;

const WelcomeMessageRow = styled.li`
  margin: 0;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    padding: 0.3rem 1.1rem;
    font-size: 1.2rem;
    border: 0.1rem solid #e7e6ea;
    border-radius: 4rem;
    background-color: #e7e6ea;
  }
`;

export default MainRoomMessages;
