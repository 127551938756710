import React from "react";
import { styled } from "styled-components";
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../utils/db';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { FreeMode } from 'swiper/modules';
import ActiveUser from "./ActiveUser";

const ActiveUsers = () => {
  const activeUserList = useLiveQuery(
    async () => {
      const users = await db.activeUsers.toArray();
      return users;
    },
  );

  return (
    <ActiveUsersWrapper>
      <div>
        <Swiper
          slidesPerView={3}
          spaceBetween={1}
          freeMode={true}
          modules={[FreeMode]}
          className="mySwiper"
          breakpoints={{
            280: {
              slidesPerView: 3,
              spaceBetween: 1,
            },
            375: {
              slidesPerView: 4.5,
              spaceBetween: 1,
            },
            600: {
              slidesPerView: 6.5,
              spaceBetween: 1,
            },
            768: {
              slidesPerView: 8.5,
              spaceBetween: 1,
            },
            1024: {
              slidesPerView: 10.5,
              spaceBetween: 1,
            },
            1280: {
              slidesPerView: 12.5,
              spaceBetween: 1,
            },
          }}>
          {activeUserList?.map((row, index) => {
            return (
              <SwiperSlide key={index}>
                <ActiveUser name={row.name} sid={row.sid} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </ActiveUsersWrapper>
  );
};

const ActiveUsersWrapper = styled.div`
  padding: 0 0 1.5rem 2.2rem;
  height: 9rem;

  & div {
    cursor: pointer;
  }

  &.swiper-android .swiper-slide, .swiper-ios .swiper-slide, .swiper-wrapper {
    transform: none;
  }
`;

export default ActiveUsers;
