import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  // <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  // </React.StrictMode>
);

if ('serviceWorker' in navigator ) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(new URL('./workers/service-worker.js', import.meta.url))
      .then(function(registration) {
        console.log('서비스 워커 등록 완료:', registration);
      })
      .catch(function(error) {
        console.error('서비스 워커 등록 실패:', error);
      });
  });
}

// 알림 권한 요청
function requestNotificationPermission() {
  if ('Notification' in window) {
    Notification.requestPermission()
      .then(permission => {
        if (permission === 'granted') {
          console.log('알림 권한이 허용되었습니다!');
          // 알림 권한이 허용된 경우, 웹 푸시 알림 등록 로직을 실행할 수 있음
        } else {
          console.warn('알림 권한이 거부되었습니다.');
        }
      })
      .catch(error => {
        console.error('알림 권한 요청 중 오류 발생:', error);
      });
  } else {
    console.warn('현재 브라우저는 알림을 지원하지 않습니다.');
  }
}

// 사용자가 페이지에 접속할 때 알림 권한 요청 대화 상자 표시
document.addEventListener('DOMContentLoaded', () => {
  requestNotificationPermission();
});
