import { atom } from 'recoil';

export const UrlPaths = {
  "main": "main",
  "personal": "personal",
}

export const isDarkAtom = atom({
  key: 'isDark',
  default: false,
});

export const messageModalInfo = atom({
  key: 'messageModal',
  default: {
    show: false,
    pid: '',
    name: '',
  },
});

export const joinInfo = atom({
  key: 'joinInfo',
  default: {
    companyName: '',
    ipAddress: '',
    tableName: '',
    nickName: '',
  }
});


export const socketObj = atom({
  key: 'socketObj',
  default: {
    sockt: '',
  }
});