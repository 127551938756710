import { io } from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_SOCKET_HOST}`,
// const socket = io(`//${window.location.hostname}:3000`,
  {
    cors: {
      origin: "*",
    },
    autoConnect: false,
    timeout: 3000, // 60초로 타임아웃 설정
    transports: ['websocket'],
    reconnection: false,
    reconnectionAttempts: 5,
  }
);


export default socket;