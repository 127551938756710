import { useState, useRef, useEffect } from 'react';
import { styled } from 'styled-components';
import { timeForToday } from '../utils/timeUtils'

const PersonalMessages = ({messages}) => {
  const [listHeight, setListHeight] = useState(0);
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      const height = listRef.current.scrollHeight;

      if (listHeight < height) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
      return setListHeight(height);
    }
  }, [messages.length, listHeight]);


  return (
    <MessagesWrapper ref={listRef}>
      <div>
        <ul>
          {messages && (
            messages.map((row, i) => {
              switch(row.type) {
                case 'you':
                  return (
                    <MessageRow key={i} $isRight={true}>
                      <Message $isRight={true}>
                        {row.message}
                        <ResDate>
                          {`${timeForToday(row.date)}`}
                        </ResDate>
                      </Message>
                    </MessageRow>
                  );
                default:
                  return (
                    <MessageRow key={i} $isRight={false}>
                      <Message $isRight={false}>
                        {row.message}
                        <ResDate>
                          {`${timeForToday(row.date)}`}
                        </ResDate>
                      </Message>
                    </MessageRow>
                  );
              }
            }
          ))}
        </ul>
      </div>
    </MessagesWrapper>
  );
};

const MessagesWrapper = styled.section`
  position: absolute;
  inset: 7rem 0 7.1rem 2rem;

  margin: 0;
  padding: 0 2.2rem;
  overflow: auto;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};

  &>div {
    padding-top: 2.4rem;
    position: relative;
    overflow: auto;
    & ul {
      padding: 0rem 0rem 1rem;
    }
  }
`;

const MessageRow = styled.li`
  margin: 0;
  padding: 1.3rem 0;
  position: relative;

  display: flex;
  justify-content: ${props => props.$isRight? `flex-end;`:`flex-start;` };
  align-items: center;
  gap: 2rem;
`;

const Message = styled.div`
  position: relative;
  padding: 2rem 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 6rem;
  font-size: 1.2rem;
  color: #000;

  border-radius: ${props => props.$isRight? `2rem 2rem 0rem 2rem`: `2rem 2rem 2rem 0rem` };
  background-color: ${props => props.$isRight? `#f3f3f3`: `#fdf2f3` };
`;

const ResDate = styled.span`
  position: absolute;
  bottom: -1.7rem;
  right: 0.3rem;

  font-weight: 600;
  color: ${(props) => props.theme.textColor};
  opacity: 0.4;
`;

export default PersonalMessages;
