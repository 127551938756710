import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { joinInfo } from '../atoms/atoms';

export const Join = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyName = queryParams.get("cname");
  const navigate = useNavigate();
  const setJoinInfoData = useSetRecoilState(joinInfo);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      companyName: companyName ? companyName : "localhost",
    }
  });

  const onValid = (data) => {
    setJoinInfoData(data);
    navigate('/main');
  }

  // == state 변경 ------------------------------------------------

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setValue("ipAddress", data?.ip);
      } catch (error) {
        setValue("ipAddress", "127.0.0.10");
        // console.log(error);
      }
    };
    fetchIp();
  }, [setValue]);

  return (
    <JoinWrapper>
      <LogoWrapper>
        <img src='/img/chear-talk-logo.png' alt='Chear Talk Logo' />
      </LogoWrapper>
      <FormWrapper onSubmit={handleSubmit(onValid)}>
        <InputWrapper>
          <div style={{display: 'none'}}>
            <label htmlFor='companyName'>Company name</label>
            <input
              {...register("companyName", { required: 'Company name is required!' })}
              placeholder="Company name"
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <div style={{display: 'none'}}>
            <label htmlFor='ipAddress'>IP</label>
            <input
              {...register("ipAddress", { required: 'IP Address is required!' })}
              placeholder="IP Address"
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <div>
            <label htmlFor='tableName'>Table name</label>
            <input
              {...register("tableName")}
              placeholder=""
            />
          </div>
        </InputWrapper>
        <InputWrapper>
          <div>
            <label htmlFor='nickName'>Nick name</label>
            <input
              {...register("nickName", { required: 'Nick name is required!' })}
              placeholder=""
            />
          </div>
          <span>{errors?.nickName?.message}</span>
        </InputWrapper>
        <JoinButton type='submit'>Join</JoinButton>
      </FormWrapper>
    </JoinWrapper>
  );
};

const JoinWrapper = styled.div`
  margin: 0 auto;
  padding-top: 5rem;
  width: 100vw;
  max-width: 50rem;
  height: 100vh;
  min-height: 63rem;
  border: 0rem solid #dadada;
`;

const LogoWrapper = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 12.8rem;
    height: 12.8rem;
  }
`;

const FormWrapper = styled.form`
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
`;

const InputWrapper = styled.div`
  position: relative;
  & div {
    padding: 0.8rem 0rem 0.7rem;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #a1a1a1;
    border-radius: 0.4rem;

    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.bgColor};
    & label {
      position: absolute;
      top: -0.9rem;
      left: 0.6rem;
      padding: 0 0.6rem;
      font-size: 1.4rem;
      color: #a1a1a1;
      background-color: #fff;

      background-color: ${(props) => props.theme.bgColor};
    }
    & input {
      padding-left: 1rem;
      border: 0;
      outline: none;
    }
  }
  & span {
    display: inline-block;
    padding-top: 0.3rem;
    padding-left: 0;
    color: #e94057;
  }
`;

const JoinButton = styled.button`
  height: 5.6rem;
  font-weight: 600;
  color: #fff;
  background-color: #e94057;
  border: 1px solid #e94057;
  border-radius: 1.2rem;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
`;


