import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { joinInfo, messageModalInfo } from '../atoms/atoms';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../utils/db';
import socket from '../utils/socket';
import Avatar from './Avatar';
import PersonalMessages from './PersonalMessages';

const MessageModal = ({worker, savePersonalMessageByEmit}) => {
  const joinInfoData = useRecoilValue(joinInfo);
  const [messageModal, setMessageModal] = useRecoilState(messageModalInfo);
  const { register, handleSubmit, setValue } = useForm();

  const messages = useLiveQuery(
    async () => {
      if(!messageModal?.pid) return [];
      //
      // Query Dexie's API
      //
      // console.log("m-pid: "+messageModal?.pid);
      const msgByPid = await db.messages.where({pid: messageModal?.pid}).toArray();

      return msgByPid;
    },
    // specify vars that affect query:
    [messageModal]
  );

  const changePersonalMessageTag = () => {
    try {
      db.personalRoom.update(messageModal?.pid, { tag: '' })
        .then(async (updated) => {
          if (updated)
            console.log("sss number 2 was renamed to Number 2");
          else {
            console.log("ss was updated - there were no friend with primary key: 2");
          }
        }
        );
    } catch (error) {
      // console.log(`Failed to add ${message}: ${error}`);
    }
  }

  const handleMessageModal = () => {
    changePersonalMessageTag();
    setMessageModal(!messageModal.show);
  }

  // const savePersonalMessageByEmit = async ({ type, fromName, fromSid, toName, toSid, message, date }) => {

  //   try {
  //     const id = await db.messages.add({ type, pid: toSid, fromSid, fromName, toSid, toName, message, date });
  //     console.log(`${message} successfully added. Got id ${id}`);
  //   } catch (error) {
  //     console.log(`Failed to add ${message}: ${error}`);
  //   }

  //   try {
  //     console.log(`${toSid} ${toName} successfully added.`);
  //     db.personalRoom.update(toSid, { sid: toSid, name: toName, message, date })
  //       .then(async (updated) => {
  //         if (updated)
  //           console.log ("Friend number 2 was renamed to Number 2");
  //         else {
  //           console.log ("Nothing was updated - there were no friend with primary key: 2");
  //           const id = await db.personalRoom.add({
  //             pid: toSid,
  //             sid: toSid,
  //             name: toName,
  //             message,
  //             date
  //           });
  //           console.log(`${message} successfully added. Got pid ${id}`);
  //         }
  //       }
  //     );
  //   } catch (error) {
  //     // console.log(`Failed to add ${message}: ${error}`);
  //   }
  // }

  const onValid = (data) => {
    worker.postMessage({
      action: 'sendPersonalMessage',
      jData: {
        fromName: joinInfoData.nickName,
        fromSid: socket.id,
        toName: messageModal.name,
        toSid: messageModal.pid,
        message: data.sendPersonalMessage,
    }});
    setValue("sendPersonalMessage", '');

    // socket.emit("sendPersonalMessage",
    //   {
    //     fromName: joinInfoData.nickName,
    //     fromSid: socket.id,
    //     toName: messageModal.name,
    //     toSid: messageModal.pid,
    //     message: data.sendPersonalMessage,
    //   },
    //   () => {
    //     savePersonalMessageByEmit({
    //       type: 'you',
    //       fromName: joinInfoData.nickName,
    //       fromSid: socket.id,
    //       toName: messageModal.name,
    //       toSid: messageModal.pid,
    //       message: data.sendPersonalMessage,
    //       date: new Date()
    //     });
    //     setValue("sendPersonalMessage", '');
    //   }
    // );
  }

  useEffect(() => {
    if(messageModal) {
      // console.log("me-pid: "+messageModal?.pid);

      document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;
    }

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };


  }, [messageModal]);

  return (
    <>
      {messageModal.show && (
        <Modal>
          <ModalBody>
            <Header>
              <Avatar name={messageModal.name}/>
              <div>
                <Nickname>{messageModal.name}</Nickname>
                <OnlineState>Online</OnlineState>
              </div>
              <img src='/img/x-mark.png' alt="close button" onClick={()=>handleMessageModal()} />
            </Header>
            <PersonalMessages messages={messages}/>
            <SendMessageWrapper onSubmit={handleSubmit(onValid)}>
              <input
                {...register("sendPersonalMessage", { required: true})}
                placeholder="Your message"
              />
              <button type='submit'>Send</button>
            </SendMessageWrapper>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default MessageModal;

const Modal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 0);
`;

const ModalBody = styled.div`
  padding: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 85%;
  background-color: rgb(255, 255, 255);
  border-radius: 3.6rem 3.6rem 0 0;
`;

const Header = styled.div`
  padding: 1.8rem 0 0 1.4rem;
  height: 7rem;
  display: flex;
  align-items: center;
  &>div{
    padding-left: 3rem;
  }
  & img {
    position: absolute;
    top: 2.3rem;
    right: 3rem;
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

const Nickname = styled.div`
  font-size: 1.6rem;
`;

const OnlineState = styled.div`
  color: #ddd;
`;

const SendMessageWrapper = styled.form`
  padding: 0 2rem;
  width: 100vw;
  height: 7rem;

  position: fixed;
  left: 0rem;
  bottom: 0rem;

  display: flex;
  align-items: center;
  gap: 2rem;
  color: ${(props) => props.theme.textColor};
  background-color: ${(props) => props.theme.bgColor};

  & input {
    padding-left: 2.4rem;
    width: 100vw;
    height: 4.5rem;
    color: #999;
    border: 0.1rem solid #e7e6ea;
    border-radius: 1.2rem;
    outline: none;
  }
  & button {
    width: 9rem;
    height: 4.8rem;
    color: #fff;
    background-color: #e94057;
    border: 0.1rem solid #e94057;
    border-radius: 1.2rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
`;